import * as CryptoJS from "crypto-js";
import { decodeJwt, JWTPayload } from 'jose';


export class JWTToken {

  public static decode(data: string): JWTPayload {
    return decodeJwt(data)
  }

  public static getUnsignedToken(data: any) {
    const header = {
      "alg": "HS256",
      "typ": "JWT",
    };

    const decodedHeader: string = JWTToken.parseAndDecode(header);
    const decodedData: string = JWTToken.parseAndDecode(data);

    return decodedHeader + "." + decodedData;
  }

  public static getSignedToken(data: any, secret: string) {
    const token = JWTToken.getUnsignedToken(data);
    return JWTToken.doSignedToken(token, secret);
  }

  public static doSignedToken(token: string, secret: string) {
    const signature: CryptoJS.lib.WordArray = CryptoJS.HmacSHA256(token, secret);
    const encodedSignature: string = JWTToken.toBase64url(signature);
    return token + "." + encodedSignature;
  }

  public static parseAndDecode(input: any): string {
    const stringedInput: string = JSON.stringify(input)
    const encodedInput: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(stringedInput);
    return JWTToken.toBase64url(encodedInput);
  }

  public static toBase64url(source: CryptoJS.lib.WordArray) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }

}
