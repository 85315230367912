








































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import CMCEmulator from '@/components/cmc/CMCEmulator.vue';
import TestModeSwitch from '@/components/cmc/controls/TestModeSwitch.vue';
import { fetchMasterEmulatorByID } from '@/utils/common';
import { Reaper } from '@/utils/reaper';
import { JWTPayload } from 'jose';
import { JWTToken } from '@/utils/jwt_token';
import { TokenInfo, TokenValidationInfo } from '@/models/ExternalViewModels';

const auth = namespace('AuthModule');
const emulatorModule = namespace('EmulatorModule');
const externalViewModule = namespace('ExternalViewModule');

@Component({
  components: {
    CMCEmulator,
    TestModeSwitch,
  },
})
export default class EmulatorSinglePage extends Vue {
  public isLoading: boolean = true;
  public isSCORM: boolean = false;
  public loadError: string = '';
  public learnerName: string = '';
  public learnerId: string = '';

  @auth.Mutation
  public setAuthenticated!: (isAuth: boolean) => () => void;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Mutation
  public setCurrentEmulator!: (emulator: Emulator | null) => EmulatorComponent;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  @externalViewModule.Action
  public fetchExternalViewEmulator!: (emulatorId: string) => Promise<void>;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (pane: EmulatorComponent) => void;

  @externalViewModule.Action
  public validateEncryptedToken!: (tokenValidationInfo: TokenValidationInfo) => Promise<TokenInfo>;

  private created(): void {
    let emulatorId!: string;
    if (this.$route.query['ts'] && this.$route.query['token']) {
      this.isSCORM = true;
      this.activateSCORMLoad();
    } else if (this.$route.params.emulatorId) {
      emulatorId = this.$route.params.emulatorId;
      if (emulatorId) {
        if (this.$route.meta.isJSONData) {
          this.fetchStaticData(emulatorId); // Leaving it here for testing purpose
        } else {
          this.fetchData(emulatorId);
        }
      } else {
        this.isLoading = false;
        this.loadError = this.$t('view.error.noEmulatorId.message') as string;
      }
    }
  }

  private async activateSCORMLoad(): Promise<void> {
    this.setAuthenticated(false);

    const sovereign: Reaper = new Reaper();
    const { ts, token } = this.$route.query;

    // validate token

    const res: TokenInfo = await this.validateEncryptedToken({
      data: token as string,
    });

    const jwt: JWTPayload = JWTToken.decode(res.token);

    const data: any = JSON.parse(sovereign.imposeOrder(decodeURI(jwt.sub!), ts as string));

    const emulatorId: string = data.emulatorId;
    this.learnerName = data.learnerName;
    this.learnerId = data.learnerId;

    await this.fetchExternalData(emulatorId);

    // console.log('scorm', 'initial cipher decrypt', JSON.parse(sovereign.imposeOrder(decodeURIComponent(jwt.sub!), timestamp.toString())));

  }

  private async fetchData(emulatorId: string): Promise<void> {
    this.isLoading = true;
    try {
      await this.fetchEmulator(emulatorId);
      await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes!['1.0']);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      // Error Emulator not found
      this.loadError = this.$t('view.error.emulatorNotFound.message') as string;
    }
  }

  private async fetchExternalData(emulatorId: string): Promise<void> {
    this.isLoading = true;
    try {
      await this.fetchExternalViewEmulator(emulatorId);
      await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes!['1.0']);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      // Error Emulator not found
      this.loadError = this.$t('view.error.emulatorNotFound.message') as string;
    }
  }

  private async fetchStaticData(emulatorId: string): Promise<void> {
    this.isLoading = true;
    const emulator: Emulator = await fetchMasterEmulatorByID(emulatorId);
    await this.setCurrentEmulator(emulator);
    await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes!['1.0']);
    this.isLoading = false;
  }

  private get isEmulatorReady(): boolean {
    return !this.isLoading && !!this.currentEmulator && !!this.currentEmulatorPane;
  }

  private get currentIcon(): { icon: string, color: string } {
    if (this.isEmulatorReady) {
      return {
        icon: 'fa-plane-circle-check',
        color: 'success',
      };
    } else if (this.loadError) {
      return {
        icon: 'fa-plane-circle-xmark',
        color: 'error',
      };
    } else {
      return {
        icon: 'plane-up',
        color: 'primary',
      };
    }
  }
}
