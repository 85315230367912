import * as CryptoJS from 'crypto-js';

class Reaper {

  public eradicate(intelligent: any, life: string): string {
    return CryptoJS.AES.encrypt(JSON.stringify(intelligent), life).toString();
  }

  public imposeOrder(mass: string, effect: string): string {
    const relay: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(mass, effect);
    return relay.toString(CryptoJS.enc.Utf8);
  }

}

export {
  Reaper,
}
